<template>
  <div>
    <b-row class="match-height">
      <b-col md="4">
        <b-card>
          <b-card-title>支付配置录入</b-card-title>
          <b-card-sub-title>仅支持银联前置商户配置</b-card-sub-title>
          <div style="height: 1rem"></div>
          <PayForm @handlePaySetting="handlePaySetting"></PayForm>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card>
          <b-card-title>最新配置</b-card-title>
          <b-card-sub-title>最新6条配置记录</b-card-sub-title>
          <div style="height: 1rem"></div>
          <MyTable
            :fields="payMerchantFields"
            :isShowHeader="false"
            :items="payMerchantList"
          ></MyTable>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardSubTitle } from "bootstrap-vue";
import PayForm from "./childComps/PayForm.vue";
import MyTable from "@/components/MyTable.vue";

import { getUserInfo } from "@/utils/api";
import { changeTips } from "@/utils/util";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardSubTitle,

    PayForm,
    MyTable,
  },
  data() {
    return {
      payMerchantFormTitle: "",
      payMerchantFields: [
        {
          key: "storeId",
          label: "门店ID",
        },
        {
          key: "key",
          label: "键名",
        },
        {
          key: "data",
          label: "配置",
        },
        {
          key: "created_at",
          label: "提交时间",
        },
      ],
      payMerchantList: [],
    };
  },
  created() {
    this.getPaySettingInfo();
  },
  methods: {
    getPaySettingInfo() {
      getUserInfo("sysAdminWeb.payConfigList", {}).then((res) => {
        if (res && res.data && res.data.code === "200") {
          this.payMerchantList = res.data.data;
          console.log(res, "支付配置信息");
        } else {
          console.log(res);
        }
      });
    },
    handlePaySetting(e) {
      if (!e.storeId) {
        return changeTips("门店ID不能为空", "warning");
      }
      if (!e.merchantId) {
        return changeTips("商户号不能为空", "warning");
      }
      if (!e.merchantSecret) {
        return changeTips("商户密钥不能为空", "warning");
      }
      getUserInfo("sysAdminWeb.payConfigAdd", e).then((res) => {
        if (res && res.data && res.data.code === "200") {
          console.log(res);
          this.getPaySettingInfo();
        } else {
          return changeTips(res.data.msg,'warning');
        }
      });
    },
  },
};
</script>

<style>
</style>

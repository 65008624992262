<template>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="门店ID"
            label-for="h-storeId"
            label-cols-md="4"
          >
            <b-form-input v-model="payMerchantParams.storeId" id="h-storeId" type="number" placeholder="请输入门店ID" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="商户号" label-for="h-merchantId" label-cols-md="4">
            <b-form-input v-model="payMerchantParams.merchantId" type="number" id="h-merchantId" placeholder="请输入商户号" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="商户密钥" label-for="h-merchantSecret" label-cols-md="4">
            <b-form-input v-model.trim="payMerchantParams.merchantSecret" id="h-merchantSecret" placeholder="请输入商户密钥" />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="submitForm"
          >
            提交
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="handleReset"
          >
            重置
          </b-button>
        </b-col>
      </b-row>
    </b-form>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BCardText,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTable,
    BCard,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BTab,
    BTabs,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
  },
  directives: {
    Ripple,
  },
    data() {
    return {
      payMerchantParams: {
        storeId: '',
        merchantId: '',
        merchantSecret: '',
      }
    };
  },
  methods: {
    handleReset() {
      this.payMerchantParams = {
        storeId: '',
        merchantId: '',
        merchantSecret: '',
      }
    },
    submitForm() {
      this.$emit('handlePaySetting',this.payMerchantParams)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
